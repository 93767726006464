import * as React from 'react'
import { Container, Grid, Box, Heading } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Link from '../../components/link'
import FlatBorder from '../../components/funky-border'

const CollectionCard = ({ collection }) => {
  if (!collection || !collection.handle) return null

  return (
    <Box key={`${collection.handle}-col`}>
      <Link to={`/collections/${collection.handle}`}>
        <FlatBorder tags={[collection.handle]}>
          <GatsbyImage
            alt=""
            image={collection.image.localFile.childImageSharp.gatsbyImageData}
          />
        </FlatBorder>
      </Link>
    </Box>
  )
}

const Collection = ({
  data: {
    birthdayCollection,
    christmasCollection,
    allSpecialCollection,
    merchCollection,
    voucherCollection,
  },
  pageContext: { breadcrumb },
}) => {
  return (
    <Layout breadcrumbs={breadcrumb}>
      <Seo
        title="All Collections | Mud Foods"
        description="Browse our delicious range of pies, squiches, gourmet meals and sides. All our products are handcrafted using family recipes and traditional methods. Shop now"
      ></Seo>

      <Container py={5}>
        <Heading as="h1" textStyle="h1">
          Pie Gifts
        </Heading>
        <Grid
          templateColumns={[
            '1fr',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
            'repeat(4, 1fr)',
          ]}
          columnGap={[2, 3, 4, 4]}
          rowGap={[0]}
        >
          {/* <CollectionCard collection={christmasCollection} /> */}
          <CollectionCard collection={birthdayCollection} />
          <CollectionCard collection={allSpecialCollection} />
          <CollectionCard collection={voucherCollection} />
          <CollectionCard collection={merchCollection} />

          {/* {allShopifyCollection.nodes.map(
            (collection) =>
              collection.title &&
              collection.image && <CollectionCard collection={collection} />
          )} */}
        </Grid>
      </Container>
    </Layout>
  )
}

export default Collection

export const query = graphql`
  query MyQuery($regex: String = "/(-gifts|-giftboxes)/") {
    allShopifyCollection(
      filter: { handle: { regex: $regex } }
      sort: { fields: handle, order: ASC }
    ) {
      nodes {
        description
        title
        handle
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1
                formats: [AUTO, WEBP]
                quality: 40
                width: 560
              )
            }
          }
        }
      }
    }
    allSpecialCollection: shopifyCollection(
      handle: { eq: "all-special-occasions-giftboxes" }
    ) {
      description
      title
      handle
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1
              formats: [AUTO, WEBP]
              quality: 40
              width: 560
            )
          }
        }
      }
    }
    christmasCollection: shopifyCollection(
      handle: { eq: "christmas-giftboxes" }
    ) {
      description
      title
      handle
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1
              formats: [AUTO, WEBP]
              quality: 40
              width: 560
            )
          }
        }
      }
    }
    birthdayCollection: shopifyCollection(
      handle: { eq: "birthday-giftboxes" }
    ) {
      description
      title
      handle
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1
              formats: [AUTO, WEBP]
              quality: 40
              width: 560
            )
          }
        }
      }
    }
    merchCollection: shopifyCollection(handle: { eq: "extra-gifts" }) {
      description
      title
      handle
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1
              formats: [AUTO, WEBP]
              quality: 40
              width: 560
            )
          }
        }
      }
    }
    voucherCollection: shopifyCollection(handle: { eq: "gift-vouchers" }) {
      description
      title
      handle
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1
              formats: [AUTO, WEBP]
              quality: 40
              width: 560
            )
          }
        }
      }
    }
  }
`
